var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"valuation-tables-wrapper pa-0 mb-2",class:{
    'desktop-only': _vm.desktopMode,
  },style:(_setup.containerStyles)},[_c('div',{staticClass:"historicals-wrapper"},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',[_c('th'),_vm._v(" "),_c('th',{staticClass:"table-title",attrs:{"colspan":"3"}},[_vm._v("\n            "+_vm._s(_setup.I18nFn("Historical"))+"\n          ")])]),_vm._v(" "),_c('tr',[_c('th'),_vm._v(" "),_vm._l((_vm.historicalYears),function(item){return _c('th',{key:item.title},[_c('span',{staticClass:"historical-title"},[_vm._v(_vm._s(_setup.I18nFn(item.title)))])])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.historicalTableFilteredData),function(row){return _c('tr',{key:row.label},[_c('th',[_vm._v(_vm._s(_setup.I18nFn(row.label)))]),_vm._v(" "),_vm._l((row.data),function(dataObj,idx){return _c('td',{key:`${idx}_${dataObj?.v}`,class:{
              'red--text':
                dataObj?.v !== null &&
                isFinite(dataObj?.v) &&
                _vm.formatter(dataObj?.v, 'pct').startsWith('('),
              'is-irr': row.label === 'IRR (Annual Return)',
            }},[(dataObj?.unauth)?[(_vm.exporting)?_c('svg',{staticClass:"ma-0 pa-0 exporting-svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13","height":"13","viewBox":"0 0 13 16","fill":"none"}},[_c('path',{staticClass:"ma-0 pa-0",attrs:{"fill":_setup.darkActive ? '#fff' : '#5F6368',"d":"M2 16c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 .5 14.5V7c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h.75V4c0-1.038.366-1.922 1.097-2.653A3.614 3.614 0 0 1 6.5.25c1.037 0 1.922.366 2.653 1.097A3.614 3.614 0 0 1 10.25 4v1.5H11c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v7.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H2Zm4.5-3.75c.412 0 .766-.147 1.06-.44.293-.294.44-.648.44-1.06 0-.412-.147-.766-.44-1.06a1.444 1.444 0 0 0-1.06-.44c-.412 0-.766.147-1.06.44-.293.294-.44.648-.44 1.06 0 .412.147.766.44 1.06.294.293.647.44 1.06.44ZM4.25 5.5h4.5V4a2.17 2.17 0 0 0-.656-1.594A2.17 2.17 0 0 0 6.5 1.75a2.17 2.17 0 0 0-1.594.656A2.17 2.17 0 0 0 4.25 4v1.5Z"}})]):_c('UnauthorizedIcon')]:(dataObj?.v !== null && isFinite(dataObj?.v))?[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v("\n                    "+_vm._s(_vm.formatter(dataObj?.v, row.format))+"\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_setup.getTitle(dataObj))}})])]:[_vm._v("-")]],2)})],2)}),0)])]),_vm._v(" "),_c('div',{staticClass:"forecast-wrapper"},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',[_c('th'),_vm._v(" "),_c('th',{staticClass:"table-title",attrs:{"colspan":"3"}},[_vm._v("\n            "+_vm._s(_setup.I18nFn("Forecast"))+" ("+_vm._s(_vm.forecastTableDateRange.start)+" -\n            "+_vm._s(_vm.forecastTableDateRange.end)+")\n          ")])]),_vm._v(" "),_c('tr',[_c('th'),_vm._v(" "),_vm._l((_vm.assumptionCases),function(assumptionCase,idx){return _c('th',{key:assumptionCase.id,staticClass:"assumption",class:{
              first: idx === 0,
            }},[_vm._v("\n            "+_vm._s(_setup.I18nFn(assumptionCase.name))+"\n          ")])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.forecastTableData),function(row){return _c('tr',{key:row.label,class:{
            highlight: row.highlight,
          }},[_c('th',[_vm._v(_vm._s(_setup.I18nFn(row.label)))]),_vm._v(" "),_vm._l((row.data),function(dataObj,idx){return _c('td',{key:`${dataObj?.v}_${idx}`,class:{
              'red--text':
                dataObj?.v !== null &&
                isFinite(dataObj?.v) &&
                _vm.formatter(dataObj?.v, 'pct').startsWith('('),
              'is-irr': row.label === 'IRR (Annual Return)',
            }},[(dataObj?.unauth)?[(_vm.exporting)?_c('svg',{staticClass:"ma-0 pa-0 exporting-svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"13","viewBox":"0 0 13 16","fill":"none"}},[_c('path',{staticClass:"ma-0 pa-0",attrs:{"fill":_setup.darkActive ? '#FFF' : '#5F6368',"d":"M2 16c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 .5 14.5V7c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h.75V4c0-1.038.366-1.922 1.097-2.653A3.614 3.614 0 0 1 6.5.25c1.037 0 1.922.366 2.653 1.097A3.614 3.614 0 0 1 10.25 4v1.5H11c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v7.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H2Zm4.5-3.75c.412 0 .766-.147 1.06-.44.293-.294.44-.648.44-1.06 0-.412-.147-.766-.44-1.06a1.444 1.444 0 0 0-1.06-.44c-.412 0-.766.147-1.06.44-.293.294-.44.648-.44 1.06 0 .412.147.766.44 1.06.294.293.647.44 1.06.44ZM4.25 5.5h4.5V4a2.17 2.17 0 0 0-.656-1.594A2.17 2.17 0 0 0 6.5 1.75a2.17 2.17 0 0 0-1.594.656A2.17 2.17 0 0 0 4.25 4v1.5Z"}})]):_c('UnauthorizedIcon')]:(dataObj?.v !== null && isFinite(dataObj?.v))?[_c('v-tooltip',{attrs:{"top":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v("\n                    "+_vm._s(_vm.formatter(dataObj?.v, row.format))+"\n                  ")])]}}],null,true)},[_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_setup.getTitle(dataObj))}})])]:[_vm._v("-")]],2)})],2)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }