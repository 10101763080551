import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'PeSummaryShareable',
  props: {
    assumptionCases: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    formatter: {
      type: Function,
      "default": function _default(v) {
        return v;
      }
    },
    forecastTableData: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    forecastTableDateRange: {
      type: Object,
      "default": function _default() {
        return {
          start: null,
          end: null
        };
      }
    },
    historicalYears: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    historicalTableFilteredData: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    desktopMode: {
      type: Boolean,
      "default": false
    },
    exporting: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var containerStyles = computed(function () {
      return {
        "--table-bg": darkActive.value ? "#29251A" : "#fff9e7",
        "--table-highlight": darkActive.value ? "#4d402e" : "#fdf3cb",
        "--table-border-color": darkActive.value ? "#594918" : "#fbbc07"
      };
    });
    var getTitle = function getTitle(dataObj) {
      if (!(dataObj !== null && dataObj !== void 0 && dataObj.unauth) && (dataObj === null || dataObj === void 0 ? void 0 : dataObj.v) !== null && isFinite(dataObj === null || dataObj === void 0 ? void 0 : dataObj.v)) {
        return dataObj === null || dataObj === void 0 ? void 0 : dataObj.label;
      }
      return "";
    };
    return {
      __sfc: true,
      darkActive: darkActive,
      I18nFn: I18nFn,
      containerStyles: containerStyles,
      getTitle: getTitle
    };
  }
};